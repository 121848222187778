import React from "react";
import PropTypes from "prop-types";

const Ancor = ({ src, children, className }) => (
  <a className={className} href={src} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
);

Ancor.propTypes = {
  src: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Ancor;
