import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ScrollingProvider } from "react-scroll-section";

import NavBar from "./components/organisms/NavBar/NavBar";
import Profesional from "./components/pages/Profesional";
import Footer from "./components/molecules/Footer/Footer";

import "./App.scss";

// ToDo Phase 1:
// - Images
// - clean public folder
// - Manual test

// TODO phase 2:
// CSS Variables vuelta (mirar si hay algo mas que se repite mucho)
// Form to contact
// New Sections

const App = () => {
  return (
    <ScrollingProvider debounceDelay="5">
      <NavBar />

      <Switch>
        <Route path="/" exact component={Profesional} />
        <Redirect to="/" />
      </Switch>

      <Footer />
    </ScrollingProvider>
  );
};

export default App;
