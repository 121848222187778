import React, { Fragment, useCallback, useState } from "react";
import { connect } from "react-redux";

import SideDrawer from "../SideDrawer/SideDrawer";
import Toolbar from "../Toolbar/Toolbar";

const NavBar = ({ profileImageSmall, loading }) => {
  const [showSideDrawer, setShowSideDrawer] = useState(false);

  const sideDrawerClosedHandler = useCallback(() => {
    setShowSideDrawer(false);
  }, []);

  const sideDrawerToggleHandler = useCallback(() => {
    setShowSideDrawer(!showSideDrawer);
  }, [showSideDrawer]);

  return (
    <Fragment>
      <Toolbar
        src={profileImageSmall}
        loading={loading}
        drawerToggleClicked={sideDrawerToggleHandler}
      />
      <SideDrawer
        src={profileImageSmall}
        loading={loading}
        open={showSideDrawer}
        closed={sideDrawerClosedHandler}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  profileImageSmall: state.aboutMeRed.profileImageSmall,
  loading: state.aboutMeRed.loading,
});

export default connect(mapStateToProps)(NavBar);
