import React, { Fragment, lazy, Suspense } from "react";
import PullToRefresh from "react-simple-pull-to-refresh";
import { Section } from "react-scroll-section";
import { isIOS } from "../../shared/utils";

import HeroIntro from "../organisms/HeroIntro/HeroIntro";

import "./Profesional.scss";

const Typography = lazy(() => import("../atoms/Typography/Typography"));
const About = lazy(() => import("../organisms/About/About"));
const Timeline = lazy(() => import("../organisms/Timeline/Timeline"));
const Skills = lazy(() => import("../organisms/Skills/Skills"));

const Profesional = () => {
  const refreshOnPullDown = () => {
    window.location.reload();
    return Promise.resolve("reloading...");
  };

  const mainContent = (
    <Fragment>
      <Section id="Home">
        <HeroIntro />
      </Section>
      <main className="profesional">
        <Suspense fallback={<p>Loading...</p>}>
          <Section id="Who I am!">
            <Typography type="h2" underlined className="about-section-title">
              Who I am!
            </Typography>
            <About />
          </Section>

          <Section id="Curriculum Vitae">
            <Typography type="h2" underlined className="timeline-section-title">
              Curriculum Vitae
            </Typography>
            <Timeline layout="" />
          </Section>

          <Section id="Skills">
            <Typography type="h2" underlined className="skills-section-title">
              Skills
            </Typography>
            <Skills />
          </Section>
        </Suspense>
      </main>
    </Fragment>
  );

  const contentForSafari = (
    <PullToRefresh onRefresh={refreshOnPullDown}>{mainContent}</PullToRefresh>
  );
  return isIOS ? contentForSafari : mainContent;
};

export default Profesional;
