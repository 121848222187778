import React from "react";
import PropTypes from "prop-types";

import "./Backdrop.scss";

const Backdrop = ({ show, clicked }) =>
  show ? (
    <div className="backdrop" onClick={clicked} role="presentation"></div>
  ) : null;

Backdrop.propTypes = {
  show: PropTypes.bool,
  clicked: PropTypes.func.isRequired,
};

export default Backdrop;
