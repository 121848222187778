import * as actionTypes from "../actions/actionTypes";

const initialState = {
  curriculum: [],
  loading: false,
  error: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CURRICULUM_START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case actionTypes.FETCH_CURRICULUM_SUCCESS:
      return {
        ...state,
        curriculum: action.curriculum,
        loading: false,
        error: null,
      };

    case actionTypes.FETCH_CURRICULUM_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default reducer;
