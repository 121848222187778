import React from "react";
import PropTypes from "prop-types";

import "./Typography.scss";

const Typography = ({
  type = "p",
  underlined = false,
  children,
  className,
}) => {
  const Type = type;
  const childrenSpan = (
    <span className={underlined ? "section-title-span" : null}>{children}</span>
  );
  const childToShow = type === "h2" ? childrenSpan : children;
  return <Type className={className}>{childToShow}</Type>;
};

Typography.propTypes = {
  type: PropTypes.oneOf(["h1", "h2", "h3", "h4", "p"]),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Typography;
