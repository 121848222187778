import React, { Fragment } from "react";
import PropTypes from "prop-types";

import Image from "../../atoms/Image/Image";
import Backdrop from "../../atoms/Backdrop/Backdrop";
import NavItems from "../../molecules/NavItems/NavItems";

import "./SideDrawer.scss";

const SideDrawer = ({ src, loading, open, closed }) => (
  <Fragment>
    <Backdrop show={open} clicked={closed} />
    <div
      className={`sidedrawer ${open ? "sidedrawer-open" : "sidedrawer-close"}`}
      onClick={closed}
      role="presentation"
    >
      <div className="sidedrawer-logo">
        <div className="sidedrawer-logo-icon">
          {loading || <Image src={src} alt="Logo" height="77" width="77" />}
        </div>
      </div>

      <nav aria-label="SideDrawerNav">
        <NavItems />
      </nav>
    </div>
  </Fragment>
);

SideDrawer.propTypes = {
  src: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  closed: PropTypes.func.isRequired,
};

export default SideDrawer;
