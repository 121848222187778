import * as actionTypes from "../actions/actionTypes";

const initialState = {
  fullName: "",
  profesion: "",
  profileImage: "",
  profileImageSmall: "",
  socialMedia: [],
  summary: [],
  loading: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ABOUTME_START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case actionTypes.FETCH_ABOUTME_SUCCESS:
      return {
        ...state,
        fullName: action.fullName,
        profesion: action.profesion,
        profileImage: action.profileImage,
        profileImageSmall: action.profileImageSmall,
        socialMedia: action.socialMedia,
        summary: action.summary,
        loading: false,
        error: null,
      };

    case actionTypes.FETCH_ABOUTME_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default reducer;
