import React from "react";
import { connect } from "react-redux";

import Typography from "../../atoms/Typography/Typography";
import Ancor from "../../atoms/Ancor/Ancor";
import Spinner from "../../atoms/Spinner/Spinner";

import { SiLinkedin, SiGithub, SiGmail } from "react-icons/si";
import { FiAlertCircle } from "react-icons/fi";

import "./HeroIntro.scss";

const HeroIntro = ({ fullName, profesion, socialMedia, loading }) => {
  const getIconsBySocial = (socialLabel) => {
    switch (socialLabel) {
      case "linkedin":
        return <SiLinkedin role="navigation" aria-label="Linkedin" />;
      case "github":
        return <SiGithub role="navigation" aria-label="Github" />;
      case "email":
        return <SiGmail role="navigation" aria-label="Gmail" />;
      default:
        return <FiAlertCircle />;
    }
  };

  if (loading) {
    return (
      <div className="herointro">
        <Spinner color="white" />
      </div>
    );
  }

  return (
    <div className="herointro">
      <div className="herointro-textbackdrop">
        <Typography type="h1">{fullName}</Typography>
        <Typography type="h2">{profesion}</Typography>
        <br />
        <div>
          {socialMedia.map((media) => (
            <Ancor key={media.label} src={media.link}>
              {getIconsBySocial(media.label)}
            </Ancor>
          ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  fullName: state.aboutMeRed.fullName,
  profesion: state.aboutMeRed.profesion,
  socialMedia: state.aboutMeRed.socialMedia,
  loading: state.aboutMeRed.loading,
});

export default connect(mapStateToProps)(HeroIntro);
