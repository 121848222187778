import React from "react";
import { useScrollSections } from "react-scroll-section";

import NavItem from "../../atoms/NavItem/NavItem";

import "./NavItems.scss";

const NavItems = () => {
  const sections = useScrollSections();

  return (
    <div className="nav-items">
      {sections.map(({ id, onClick, selected }) => (
        <NavItem key={id} id={id} onClick={onClick} selected={selected} />
      ))}
    </div>
  );
};

export default NavItems;
