import React from "react";
import PropTypes from "prop-types";
import { useScrollSection } from "react-scroll-section";

import Image from "../../atoms/Image/Image";
import NavItems from "../../molecules/NavItems/NavItems";
import { BiMenu } from "react-icons/bi";

import "./Toolbar.scss";

const Toolbar = ({ src, loading, drawerToggleClicked }) => {
  const { onClick } = useScrollSection("Home");

  return (
    <header className="toolbar">
      <div className="drawer-toggle">
        <BiMenu onClick={drawerToggleClicked} />
      </div>

      <div className="toolbar-logo" onClick={onClick} role="presentation">
        <div className="toolbar-logo-icon">
          {loading || <Image src={src} alt="Logo" width="250" height="250" />}
        </div>
      </div>

      <nav className="desktop-only-nav" aria-label="ToolbarNav">
        <NavItems />
      </nav>
    </header>
  );
};

Toolbar.propTypes = {
  src: PropTypes.string.isRequired,
  drawerToggleClicked: PropTypes.func.isRequired,
};

export default Toolbar;
