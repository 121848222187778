import React from "react";
import { connect } from "react-redux";

import Typography from "../../atoms/Typography/Typography";

import "./Footer.scss";

const Footer = ({ fullName }) => (
  <footer className="footer">
    <Typography>
      {fullName} Ⓡ {new Date().getFullYear()}
    </Typography>
  </footer>
);

const mapStateToProps = (state) => ({
  fullName: state.aboutMeRed.fullName,
  loading: state.aboutMeRed.loading,
});

export default connect(mapStateToProps)(Footer);
