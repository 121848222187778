import React, { memo } from "react";
import PropTypes from "prop-types";

import "./Spinner.scss";

const Spinner = ({ color = "#000" }) => {
  const borderColor =
    color === "#000" ? "rgba(0, 0, 0, 0.3)" : "rgba(255, 255, 255, 0.3)";
  return (
    <div className="spinner-container">
      <div
        className="spinner"
        style={{
          borderColor,
          borderTopColor: color,
        }}
      ></div>
    </div>
  );
};

Spinner.propTypes = {
  className: PropTypes.string,
};

export default memo(Spinner);
