import React from "react";
import PropTypes from "prop-types";

import "./NavItem.scss";

const NavItem = ({ id, onClick, selected }) => (
  <div className="navitem" onClick={onClick} role="presentation">
    <span className={selected ? "navitem-link-active" : null}>
      {id.toUpperCase()}
    </span>
  </div>
);

NavItem.propTypes = {
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default NavItem;
